export const menu = [
  {
    url : '/dashboard',
    label : 'Dashboard'
  },
  {
    url : '/page-content',
    label : 'Page Content',
    child : [
      {
        url : 'home',
        label :'Home'
      },
      {
        url : 'meeting-room',
        label :'Meeting Room'
      },
    ]
  },
  {
    url : '/rooms',
    label : 'Rooms'
  },
  {
    url : '/promo',
    label : 'Promo'
  },
  {
    url : '/gallery',
    label : 'Gallery'
  },
  {
    url : '/journal',
    label : 'Journal'
  },
]