<template>
<div class="topbar-item">
  <div id="kt_quick_user_toggle" class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto">
    <span class="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
      Hi,
    </span>
    <span class="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
      User
    </span>
    <span class="symbol symbol-35">
      <span class="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">
        S
      </span>
    </span>
  </div>

  <div id="kt_quick_user" ref="kt_quick_user" class="offcanvas offcanvas-right p-10">
    <!--begin::Header-->
    <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
      <h3 class="font-weight-bold m-0">
        User Profile
        <!-- <small class="text-muted font-size-sm ml-2">12 messages</small> -->
      </h3>
      <a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
        <i class="ki ki-close icon-xs text-muted"></i>
      </a>
    </div>
    <!--end::Header-->

    <!--begin::Content-->
    <perfect-scrollbar class="offcanvas-content pr-5 mr-n5 scroll" style="max-height: 90vh; position: relative;">
      <!--begin::Header-->
      <div class="d-flex align-items-center mt-5">
        <div class="symbol symbol-100 mr-5">
          <img class="symbol-label" :src="currentUser.img" alt="" />
          <!-- <i class="symbol-badge bg-success"></i> -->
        </div>
        <div class="d-flex flex-column">
          <a href="#" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
            {{currentUser.name}}
          </a>
          <div class="text-muted mt-1">{{currentUser.role}}</div>
          <div class="navi mt-2">
            <a href="#" class="navi-item">
              <span class="navi-link p-0 pb-2">
                <span class="navi-icon mr-1">
                  <span class="svg-icon svg-icon-lg svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Mail.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </span>
                <span class="navi-text text-muted text-hover-primary">
                  {{currentUser.email}}
                </span>
              </span>
            </a>
          </div>
          <button class="btn btn-light-primary btn-bold" @click="onLogout">
            Sign out
          </button>
        </div>
      </div>
      <!--end::Header-->
    </perfect-scrollbar>
    <!--end::Content-->
  </div>
</div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
  name: "KTQuickUser",
  data() {
    return {
      list: [{
          title: "Another purpose persuade",
          desc: "Due in 2 Days",
          alt: "+28%",
          svg: "media/svg/icons/Home/Library.svg",
          type: "warning"
        },
        {
          title: "Would be to people",
          desc: "Due in 2 Days",
          alt: "+50%",
          svg: "media/svg/icons/Communication/Write.svg",
          type: "success"
        },
        {
          title: "Purpose would be to persuade",
          desc: "Due in 2 Days",
          alt: "-27%",
          svg: "media/svg/icons/Communication/Group-chat.svg",
          type: "danger"
        },
        {
          title: "The best product",
          desc: "Due in 2 Days",
          alt: "+8%",
          svg: "media/svg/icons/General/Attachment2.svg",
          type: "info"
        }
      ]
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    ...mapActions(['signOut']),
    onLogout() {
      this.signOut().then(() => {
        this.$router.push('/login')
      })
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    })
  },
};
</script>
