<template>
<ul class="menu-nav">
  <!-- Home -->
  <router-link :to="mn.url" v-slot="{ href, navigate, isActive, isExactActive }" v-for="(mn,mnIndex) in menu" :key="`menu-${mnIndex}`">
    <li aria-haspopup="true" :data-menu-toggle="(mn.child) ? 'click' : 'hover'" class="menu-item" :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]">
      <a href="#" class="menu-link menu-toggle" v-if="mn.child">
        <span class="menu-text"> {{mn.label}} </span>
      </a>
      <a :href="href" class="menu-link" @click="navigate" v-else>
        <span class="menu-text"> {{mn.label}} </span>
      </a>
      <div class="menu-submenu menu-submenu-classic" v-if="mn.child">
        <ul class="menu-subnav">
          <router-link :to="`${mn.url}/${mnc.url}`" v-slot="{ href, navigate, isActive, isExactActive }" v-for="(mnc,mncIndex) in mn.child" :key="`menuchild-${mncIndex}`">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> {{mnc.label}} </span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
  </router-link>
</ul>
</template>

<script>
import {menu} from '@/core/config/menu'
export default {
  name: "KTMenu",
  data(){
    return {
      menu : menu
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
